import { Routes, Route, Navigate } from 'react-router-dom';

import { MainLayout } from 'layouts';

import {
	CompanyRootView,
	BusinessRootView,
	ServiceRootView,
	NewsRootView,
	NewsDetailView
} from 'views';

// ----------------------------------------------------------------------

const RouteConfig = () => {
	return (
		<Routes>
			<Route path={'/'} element={<Navigate to={'/company'} />} />
			<Route element={<MainLayout />}>
				{/* 회사 소개 */}
				<Route path={'/company'} element={<CompanyRootView />} />
				{/* 사업 소개 */}
				<Route path={'/business'} element={<BusinessRootView />} />
				{/* 서비스 소개 */}
				<Route path={'/service'} element={<ServiceRootView />} />
				{/* 뉴스룸 */}
				<Route path={'/news'} element={<NewsRootView />} />
				<Route path={'/news/detail/:id'} element={<NewsDetailView />} />
			</Route>
		</Routes>
	);
};

export default RouteConfig;
