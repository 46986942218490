import {
	Box,
	ButtonBase,
	Container,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { Trans, useTranslation } from 'react-i18next';

import images from 'assets/images';
import { IconArrowRight } from 'assets/svg';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const MoveBusiness = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const navigate = useNavigate();

	// ----------------------------------------------------------------------

	return (
		<Box
			className={matchUpMd ? 'MoveBusiness' : 'M-MoveBusiness'}
			sx={{
				background: `linear-gradient(rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%), url(${images.CompanyMoveBusiness})`,
				backgroundPosition: matchUpMd ? 'center' : 'top',
				backgroundSize: 'cover'
			}}
		>
			<Container maxWidth={'lg'} className={'px-0'}>
				<Box px={matchUpMd ? 0 : '2rem'}>
					<Typography variant={matchUpMd ? 'h2' : 'h12'} color={'white1'}>
						<Trans
							i18nKey={
								'사업 소개.콘텐츠마당이 하는 일, 전 세계를 연결하는 일입니다'
							}
						/>
					</Typography>
					<ButtonBase
						className={'MoveBusiness-Button'}
						sx={{ mt: matchUpMd ? '3rem' : '2rem' }}
						onClick={() => navigate('/business')}
					>
						<Typography
							variant={matchUpMd ? 'caption2' : 'caption5'}
							color={'white1'}
							mr={'0.8rem'}
						>
							{t('메뉴.사업 소개')}
						</Typography>

						<IconArrowRight
							width={15}
							height={10}
							stroke={theme.palette.white1}
						/>
					</ButtonBase>
				</Box>
			</Container>
		</Box>
	);
};

export default MoveBusiness;
