import { MoreView } from 'views/components';

import { MainImage, FancastSection } from 'views/service/components';

// ----------------------------------------------------------------------

const ServiceRootView = () => {
	return (
		<div>
			<MainImage />
			<FancastSection />
			<MoreView />
		</div>
	);
};

export default ServiceRootView;
