import { Trans, useTranslation } from 'react-i18next';

import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';
import { Fragment } from 'react';

// ----------------------------------------------------------------------

const graphData = [
	{
		text: '사업 개요.현지와의 협업',
		text_mobile: '사업 개요.M현지와의 협업',
		width: '100%',
		value: 38
	},
	{
		text: '사업 개요.쌍방향 문화교류 증진',
		text_mobile: '사업 개요.M쌍방향 문화교류 증진',
		width: '100%',
		value: 35.4
	},
	{
		text: '사업 개요.지역별 맞춤형 콘텐츠',
		text_mobile: '사업 개요.M지역별 맞춤형 콘텐츠',
		width: '50%',
		value: 16
	},
	{
		text: '사업 개요.콘텐츠 외의 소비재 구매',
		text_mobile: '사업 개요.M콘텐츠 외의 소비재 구매',
		width: '27%',
		value: 7.3
	},
	{
		text: '사업 개요.해외스타 등 인적자원 활용',
		text_mobile: '사업 개요.M해외스타 등 인적자원 활용',
		width: '12%',
		value: 3.3
	}
];

// ----------------------------------------------------------------------

const Intro = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const renderMobile = () => {
		return (
			<Grid container px={'2rem'} mt={'5rem'} columnSpacing={1}>
				<Grid item xs={6}>
					<Box className={'M-Business-Intro-Img'}>
						<Box className={'M-Business-Intro-Img-Box'} />
						<img src={images.BusinessIntro1} />
					</Box>
					<Typography variant={'caption5'} coclor={'black1'} display={'block'}>
						{t('사업 개요.글로벌 K-POP 팬덤 증가 추이')}
					</Typography>
					<Typography
						variant={'caption3'}
						coclor={'black1'}
						mt={'0.8rem'}
						mb={'1.5rem'}
						fontWeight={700}
						display={'block'}
						letterSpacing={i18n.language === 'jp' && '-0.07rem'}
					>
						<Trans
							i18nKey={
								'사업 개요.M1억 2천만명 > 1억 5천만명 1년 사이 약 3천만명 증가'
							}
						/>
					</Typography>
					<Box
						sx={{ typography: 'caption8', lineHeight: '1.5', color: 'gray5' }}
					>
						{t(
							'사업 개요.M* 브랜드 파워 지수(Brand Power Index) : 한류의 현재 인기도와 미래 잠재력 지수에 각각 50% 가중치 적용해 100점으로 환산'
						)}
					</Box>
					<Typography lineHeight={0.1}></Typography>
				</Grid>
				<Grid item xs={6}>
					<Box className={'M-Business-Intro-Img'}>
						<Box className={'M-Business-Intro-Img-Box'} />
						<img src={images.BusinessIntro2} />
					</Box>
					<Typography variant={'caption5'} coclor={'black1'} display={'block'}>
						{t('사업 개요.K-콘텐츠 브랜드 파워 지수*')}
					</Typography>
					<Typography
						variant={'caption3'}
						coclor={'black1'}
						mt={'0.8rem'}
						mb={'1.5rem'}
						fontWeight={700}
						display={'block'}
					>
						<Trans i18nKey={'사업 개요.M58.5점 > 61.6점 3.1점 증가'} />
					</Typography>
					<Box
						sx={{ typography: 'caption8', lineHeight: '1.5', color: 'gray5' }}
					>
						{t(
							'사업 개요.* 참조 : 우리문화신문 - ‘2022년 해외 한류 실태조사’ 결과 한류 콘텐츠 소비와 관심 증가 (2022)'
						)}
					</Box>
				</Grid>
				<Grid item xs={6} mt={'5rem'}>
					<Box className={'M-Business-Intro-Img'}>
						<Box className={'M-Business-Intro-Img-Box'} />
						<img src={images.BusinessIntro3} />
					</Box>
					<Typography variant={'caption5'} coclor={'black1'} display={'block'}>
						<Trans i18nKey={'사업 개요.M글로벌 사용자의 K-콘텐츠 접속 경로'} />
					</Typography>
					<Typography
						variant={'caption3'}
						coclor={'black1'}
						mt={'0.8rem'}
						mb={'1.5rem'}
						fontWeight={700}
						display={'block'}
					>
						{t('사업 개요.온라인‧모바일 플랫폼')}
					</Typography>
				</Grid>
				<Grid item xs={6} mt={'5rem'}>
					<Box className={'M-Business-Intro-Img'}>
						<Box className={'M-Business-Intro-Img-Box'} />
						<img src={images.BusinessIntro4} />
					</Box>
					<Typography variant={'caption5'} coclor={'black1'} display={'block'}>
						<Trans
							i18nKey={
								'사업 개요.M글로벌 사용자가 온라인‧모바일 플랫폼을 통해 가장 많이 접한 K-콘텐츠'
							}
						/>
					</Typography>
					<Typography
						variant={'caption3'}
						coclor={'black1'}
						mt={'0.8rem'}
						mb={'1.5rem'}
						fontWeight={700}
						display={'block'}
					>
						{t('사업 개요.음악 관련 80%')}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	// ----------------------------------------------------------------------

	return (
		<Box id={'business-intro'}>
			<Container maxWidth={'lg'} className={'px-0'}>
				<Grid container py={matchUpMd ? '12rem' : '6rem'} columns={13}>
					<Grid
						item
						xs={12}
						md={5}
						pl={matchUpMd ? 0 : '2rem'}
						pr={matchUpMd ? 0 : '2rem'}
					>
						<Typography variant={matchUpMd ? 'h9' : 'h13'} color={'black1'}>
							<Trans
								i18nKey={
									matchUpMd
										? '사업 개요.K-콘텐츠의 글로벌 영향력'
										: '사업 개요.K-콘텐츠의 글로벌 영향력'
								}
							/>
						</Typography>
					</Grid>
					{!matchUpMd && renderMobile()}
					{matchUpMd && (
						<Grid item xs={12} md={8}>
							<Grid container pl={'3rem'}>
								<Grid
									item
									xs={12}
									display={'flex'}
									alignItems={'center'}
									mb={'2.4rem'}
								>
									<Box className={'Business-Intro-Img'}>
										<Box className={'Business-Intro-Img-Box'} />
										<img src={images.BusinessIntro1} />
									</Box>
									<Box pl={'2.4rem'}>
										<Typography
											variant={'caption2'}
											coclor={'black1'}
											display={'block'}
										>
											{t('사업 개요.글로벌 K-POP 팬덤 증가 추이')}
										</Typography>
										<Typography
											variant={'h13'}
											coclor={'black1'}
											mt={'1.2rem'}
											mb={'0.8rem'}
										>
											<Trans
												i18nKey={
													'사업 개요.1억 2천만명 > 1억 5천만명 1년 사이 약 3천만명 증가'
												}
											/>
										</Typography>
										<Typography variant={'caption5'} color={'gray5'}>
											<Trans
												i18nKey={
													'사업 개요.* 브랜드 파워 지수(Brand Power Index) : 한류의 현재 인기도와 미래 잠재력 지수에 각각 50% 가중치 적용해 100점으로 환산'
												}
											/>
										</Typography>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									display={'flex'}
									alignItems={'center'}
									mb={'2.4rem'}
								>
									<Box className={'Business-Intro-Img'}>
										<Box className={'Business-Intro-Img-Box'} />
										<img src={images.BusinessIntro2} />
									</Box>
									<Box pl={'2.4rem'}>
										<Typography
											variant={'caption2'}
											coclor={'black1'}
											display={'block'}
										>
											{t('사업 개요.K-콘텐츠 브랜드 파워 지수*')}
										</Typography>
										<Typography
											variant={'h13'}
											coclor={'black1'}
											mt={'1.2rem'}
											mb={'0.8rem'}
										>
											<Trans i18nKey={'사업 개요.58.5점 > 61.6점 3.1점 증가'} />
										</Typography>
										<Typography variant={'caption5'} color={'gray5'}>
											<Trans
												i18nKey={
													'사업 개요.* 참조 : 우리문화신문 - ‘2022년 해외 한류 실태조사’ 결과 한류 콘텐츠 소비와 관심 증가 (2022)'
												}
											/>
										</Typography>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									display={'flex'}
									alignItems={'center'}
									mb={'2.4rem'}
								>
									<Box className={'Business-Intro-Img'}>
										<Box className={'Business-Intro-Img-Box'} />
										<img
											src={images.BusinessIntro3}
											style={{ width: '21.5rem', height: '22.8rem' }}
										/>
									</Box>
									<Box pl={'2.4rem'}>
										<Typography
											variant={'caption2'}
											coclor={'black1'}
											display={'block'}
										>
											<Trans
												i18nKey={'사업 개요.글로벌 사용자의 K-콘텐츠 접속 경로'}
											/>
										</Typography>
										<Typography
											variant={'h13'}
											coclor={'black1'}
											mt={'1.2rem'}
											mb={'0.8rem'}
										>
											<Trans i18nKey={'사업 개요.온라인‧모바일 플랫폼'} />
										</Typography>
									</Box>
								</Grid>
								<Grid item xs={12} display={'flex'} alignItems={'center'}>
									<Box className={'Business-Intro-Img'}>
										<Box className={'Business-Intro-Img-Box'} />
										<img src={images.BusinessIntro4} />
									</Box>
									<Box pl={'2.4rem'}>
										<Typography
											variant={'caption2'}
											coclor={'black1'}
											display={'block'}
										>
											<Trans
												i18nKey={
													'사업 개요.글로벌 사용자가 온라인‧모바일 플랫폼을 통해 가장 많이 접한 K-콘텐츠'
												}
											/>
										</Typography>
										<Typography
											variant={'h13'}
											coclor={'black1'}
											mt={'1.2rem'}
											mb={'0.8rem'}
										>
											<Trans i18nKey={'사업 개요.음악 관련 80%'} />
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid
					container
					pb={matchUpMd ? '12rem' : '6rem'}
					px={matchUpMd ? 0 : '2rem'}
				>
					<Grid item xs={12}>
						<Typography
							variant={
								matchUpMd
									? i18n.language === 'jp'
										? 'h11'
										: 'h9'
									: i18n.language === 'jp'
									? 'h14'
									: 'h13'
							}
							color={'black1'}
						>
							<Trans
								i18nKey={
									matchUpMd
										? '사업 개요.다양한 콘텐츠, 쉬운 인터페이스, 그리고 트렌디한 디자인을 반영하여 글로벌 No.1 팬덤 플랫폼으로 발전시켜 나갈 계획입니다.'
										: '사업 개요.M다양한 콘텐츠, 쉬운 인터페이스, 그리고 트렌디한 디자인을 반영하여 글로벌 No.1 팬덤 플랫폼으로 발전시켜 나갈 계획입니다.'
								}
							/>
						</Typography>
					</Grid>
				</Grid>
			</Container>
			<Box
				className={
					matchUpMd ? 'Business-Intro-Graph' : 'M-Business-Intro-Graph'
				}
				sx={{
					background: `linear-gradient(rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%), url(${images.BusinessIntro5})`,
					backgroundPositionY: 'bottom',
					backgroundSize: 'cover'
				}}
			>
				<Container maxWidth={'lg'} className={'px-0'}>
					<Grid container px={matchUpMd ? 0 : '2rem'}>
						<Grid item xs={12} md={5} mb={matchUpMd ? 0 : '1.5rem'}>
							<Typography
								variant={
									matchUpMd ? 'h5' : i18n.language === 'en' ? 'h12' : 'h10'
								}
								color={'white1'}
							>
								<Trans i18nKey={'사업 개요.글로벌 플랫폼을 위한 인사이트'} />
							</Typography>
						</Grid>
						<Grid item xs={12} md={7}>
							<Grid container rowSpacing={matchUpMd ? 6 : 0}>
								{graphData.map((item, index) => (
									<Fragment key={`Business-Graph-${index}`}>
										<Grid item xs={12} md={5} mt={matchUpMd ? 0 : '2.5rem'}>
											<Typography
												variant={matchUpMd ? 'caption2' : 'caption4'}
												color={'white1'}
											>
												<Trans
													i18nKey={matchUpMd ? item.text : item.text_mobile}
												/>
											</Typography>
										</Grid>
										<Grid item xs={12} md={7}>
											<Box
												display={'flex'}
												alignItems={'center'}
												justifyContent={'space-between'}
											>
												<Box
													className={'Business-Intro-Graph-Bar'}
													width={item.width}
													height={matchUpMd ? '0.8rem' : '0.5rem'}
													mr={'0.8rem'}
												/>
												<Box display={'flex'} alignItems={'center'}>
													<Typography
														variant={matchUpMd ? 'h13' : 'caption3'}
														color={'white1'}
														fontWeight={500}
													>
														{t(item.value)}
													</Typography>
													<Typography
														variant={matchUpMd ? 'caption1' : 'caption3'}
														color={'white1'}
													>
														%
													</Typography>
												</Box>
											</Box>
										</Grid>
									</Fragment>
								))}
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
	);
};

export default Intro;
