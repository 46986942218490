import { createTheme } from '@mui/material';

import muiStyleOverride from 'theme/muiStyleOverride';

import typography from './typography';
import palette from './palette';

// ----------------------------------------------------------------------

export const theme = () => {
	const options = {
		typography: typography(),
		palette: palette()
	};

	const theme = createTheme(options);
	theme.components = muiStyleOverride();

	return theme;
};

export default theme;
