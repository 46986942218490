import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import {
	Link,
	Box,
	Container,
	Grid,
	useTheme,
	useMediaQuery,
	Typography
} from '@mui/material';

import * as ReactScroll from 'react-scroll';

import { headerNav } from 'navigation';

// ----------------------------------------------------------------------

const Footer = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const scroller = ReactScroll.scroller;

	const dropdownRef = useRef(null);
	const [isOpenDropdown, setIsOpenDropdown] = useState(false);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleClickOutside = (event) => {
		if (!dropdownRef.current.contains(event.target)) {
			setIsOpenDropdown(false);
		}
	};

	const handleClickDropdown = () => {
		setIsOpenDropdown(!isOpenDropdown);
	};

	const handleNavigateAndScroll = async (navUrl, scrollId) => {
		await navigate(navUrl);
		await scroller.scrollTo(scrollId, {
			smooth: true,
			spy: true,
			offset:
				navUrl === '/news' ? (matchUpMd ? -60 : -50) : matchUpMd ? -160 : -120
		});
	};

	// ----------------------------------------------------------------------

	return (
		<Box className={'Footer'} pt={matchUpMd ? '8rem' : '5rem'}>
			<Container maxWidth={'lg'} className={'px-0'}>
				{/* 메뉴 */}
				<Grid container px={matchUpMd ? 0 : '2rem'} rowSpacing={4}>
					{headerNav.map((nav, index) => (
						<Grid key={`Footer-Menu-Main-${index}`} item xs={4} md={2}>
							<Link href={nav.main.url} className={'Footer-Nav-Main-Item'}>
								{t(nav.main.text)}
							</Link>
							<ul
								className={'Footer-Nav-Sub'}
								style={{ marginTop: matchUpMd ? '2.4rem' : '1.5rem' }}
							>
								{nav.sub.map((item, index) => (
									<li
										key={`Footer-Menu-Sub-${index}`}
										style={{ marginBottom: matchUpMd ? '1.6rem' : '1.2rem' }}
									>
										<Link
											className={'Footer-Nav-Sub-Item'}
											onClick={() =>
												handleNavigateAndScroll(nav.main.url, item.scrollId)
											}
										>
											{t(item.text)}
										</Link>
									</li>
								))}
							</ul>
						</Grid>
					))}
				</Grid>
				{/*	사업자 정보 등 */}
				<Grid
					container
					pt={matchUpMd ? '8rem' : '4rem'}
					pb={matchUpMd ? '8rem' : '5rem'}
					px={matchUpMd ? 0 : '2rem'}
				>
					{matchUpMd && (
						<Fragment>
							<Grid item xs={12}>
								<Typography
									variant={'caption2'}
									color={'black1'}
									fontWeight={700}
								>
									{t('푸터.주식회사 콘텐츠마당')}
								</Typography>
							</Grid>
							<Grid item xs={12} md={8} mt={'1.5rem'}>
								<Typography variant={'caption5'} color={'gray6'}>
									<Trans i18nKey={'푸터.사업자정보'} />
								</Typography>
							</Grid>
						</Fragment>
					)}
					<Grid
						item
						xs={12}
						md={4}
						container
						direction={'row'}
						alignItems={'flex-end'}
						justifyContent={'flex-end'}
					>
						{/* TODO: 회사 소개서 내용 최신으로 업데이트한 이후에 추가 예정*/}
						{/*<ButtonBase*/}
						{/*	className={matchUpMd ? 'Footer-Button' : 'M-Footer-Button'}*/}
						{/*>*/}
						{/*	<Typography mr={'8px'}>*/}
						{/*		{t('푸터.회사소개서 다운받기')}*/}
						{/*	</Typography>*/}
						{/*	<IconArrowDown*/}
						{/*		width={12}*/}
						{/*		height={12}*/}
						{/*		stroke={theme.palette.black2}*/}
						{/*	/>*/}
						{/*</ButtonBase>*/}

						{/*<Box*/}
						{/*	ref={dropdownRef}*/}
						{/*	className={*/}
						{/*		matchUpMd ? 'Footer-Site-Wrapper' : 'M-Footer-Site-Wrapper'*/}
						{/*	}*/}
						{/*>*/}
						{/*	<ButtonBase*/}
						{/*		className={*/}
						{/*			matchUpMd ? 'Footer-Site-Dropdown' : 'M-Footer-Site-Dropdown'*/}
						{/*		}*/}
						{/*		onClick={handleClickDropdown}*/}
						{/*	>*/}
						{/*		<Box mr={'0.8rem'}>{t('푸터.관련 사이트 바로가기')}</Box>*/}

						{/*		<IconArrowRight*/}
						{/*			width={12}*/}
						{/*			height={12}*/}
						{/*			stroke={theme.palette.black2}*/}
						{/*		/>*/}
						{/*	</ButtonBase>*/}
						{/*	{isOpenDropdown && (*/}
						{/*		<Fade in={isOpenDropdown}>*/}
						{/*			<Box*/}
						{/*				className={'Footer-Site-Dropdown-Item-Wrapper'}*/}
						{/*				top={'-10.8rem'}*/}
						{/*			>*/}
						{/*				<ButtonBase*/}
						{/*					disableRipple*/}
						{/*					className={'Footer-Site-Dropdown-Item'}*/}
						{/*					onClick={handleOpenFancastWindow}*/}
						{/*				>*/}
						{/*					{t('메뉴.팬캐스트')}*/}
						{/*				</ButtonBase>*/}
						{/*				<ButtonBase*/}
						{/*					disableRipple*/}
						{/*					className={'Footer-Site-Dropdown-Item'}*/}
						{/*					onClick={handleOpenFanseemWindow}*/}
						{/*				>*/}
						{/*					{t('메뉴.팬심')}*/}
						{/*				</ButtonBase>*/}
						{/*			</Box>*/}
						{/*		</Fade>*/}
						{/*	)}*/}
						{/*</Box>*/}
					</Grid>
					{!matchUpMd && (
						<Fragment>
							<Grid item xs={12}>
								<Typography
									variant={'caption2'}
									color={'black1'}
									fontWeight={700}
								>
									{t('푸터.주식회사 콘텐츠마당')}
								</Typography>
							</Grid>
							<Grid item xs={12} md={8} mt={'1.5rem'}>
								<Typography variant={'caption5'} color={'gray6'}>
									<Trans i18nKey={'푸터.사업자정보'} />
								</Typography>
							</Grid>
						</Fragment>
					)}
					<Grid item xs={12} mt={'1rem'}>
						<Typography
							variant={matchUpMd ? 'caption7' : 'caption8'}
							color={'gray5'}
						>
							{t('푸터.©2023 CONTENTSMADANG, INC. ALL Rights Reserved')}
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
