import { Box } from '@mui/material';

import { SubIndex, MoreView } from 'views/components';
import {
	MainImage,
	Intro,
	Partner,
	ConnectionBusiness,
	Contact
} from 'views/business/components';

const businessSubIndex = [
	{
		text: '메뉴.사업 개요',
		value: 'business-intro'
	},
	{
		text: '메뉴.파트너 소개',
		value: 'business-partner'
	},
	{
		text: '메뉴.연계 사업',
		value: 'business-connection'
	},
	{
		text: '메뉴.제휴 문의',
		value: 'business-contact'
	}
];

// ----------------------------------------------------------------------

const BusinessRootView = () => {
	return (
		<div>
			<MainImage />
			<SubIndex data={businessSubIndex} />
			<Intro />
			<Box id={'business-partner'} bgcolor={'gray1'}>
				<Partner />
			</Box>
			<ConnectionBusiness />
			<MoreView />
			<Contact />
		</div>
	);
};

export default BusinessRootView;
