import { Trans, useTranslation } from 'react-i18next';
import {
	Box,
	Container,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import images from 'assets/images';

// ----------------------------------------------------------------------

const MainImage = () => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	// ----------------------------------------------------------------------

	return (
		<Box
			className={matchUpMd ? 'MainImage' : 'M-MainImage'}
			sx={{
				background: `linear-gradient(rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%), url(${images.ServiceMain})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover'
			}}
		>
			<Container className={'MainImage-Title'} sx={{ flexDirection: 'column' }}>
				{i18n.language !== 'en' && (
					<Box mb={matchUpMd ? '1rem' : 0} textAlign={'center'}>
						<Typography
							variant={matchUpMd ? 'h5' : 'caption1'}
							fontWeight={700}
							color={'white1'}
							align={'center'}
						>
							<Trans
								i18nKey={
									matchUpMd
										? '서비스 소개.가장 트렌디한 글로벌 팬덤 플랫폼, 팬캐스트'
										: '서비스 소개.M가장 트렌디한 글로벌 팬덤 플랫폼, 팬캐스트'
								}
							/>
						</Typography>
					</Box>
				)}
				<Box>
					<Typography
						variant={matchUpMd ? 'h1' : 'h11'}
						color={'white1'}
						align={'center'}
					>
						<Trans i18nKey={'서비스 소개.Discover Something New, FANCAST'} />
					</Typography>
				</Box>
			</Container>
		</Box>
	);
};

export default MainImage;
