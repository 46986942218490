import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { SubIndex, MoreView } from 'views/components';
import {
	MainImage,
	Intro,
	History,
	MoveBusiness,
	Management,
	Location
} from 'views/company/components';
import { NoticeDialog } from 'views/components';

// ----------------------------------------------------------------------

const companySubIndex = [
	{
		text: '메뉴.회사 개요',
		value: 'company-intro'
	},
	{
		text: '메뉴.히스토리',
		value: 'company-history'
	},
	{
		text: '메뉴.경영진 소개',
		value: 'company-management'
	},
	{
		text: '메뉴.찾아오시는 길',
		value: 'company-location'
	}
];

// 공지사항 다이얼로그 노출 기간
const NOTICE_DIALOG_OPEN_DATE = new Date('2024-03-21');
const NOTICE_DIALOG_CLOSE_DATE = new Date('2024-04-12');

// ----------------------------------------------------------------------

const CompanyRootView = () => {
	const { pathname } = useLocation();

	const [noticeDialogOpen, setNoticeDialogOpen] = useState(false);

	useEffect(() => {
		if (pathname === '/company') {
			const today = new Date();
			if (
				today >= NOTICE_DIALOG_OPEN_DATE &&
				today <= NOTICE_DIALOG_CLOSE_DATE
			) {
				setNoticeDialogOpen(true);
			}
		}

		return () => {
			setNoticeDialogOpen(false);
		};
	}, [pathname]);

	// ----------------------------------------------------------------------

	return (
		<div>
			<MainImage />
			<SubIndex data={companySubIndex} />
			<Intro />
			<History />
			<MoveBusiness />
			<Management />
			<MoreView />
			<Location />

			<NoticeDialog open={noticeDialogOpen} setOpen={setNoticeDialogOpen} />
		</div>
	);
};

export default CompanyRootView;
