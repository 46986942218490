import {
	Box,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const Location = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	// ----------------------------------------------------------------------

	return (
		<Box
			id={'company-location'}
			pt={matchUpMd ? '10.6rem' : '5rem'}
			pb={matchUpMd ? '13.4rem' : '5rem'}
			px={matchUpMd ? 0 : '2rem'}
			className={'CompanyLocation'}
		>
			<Container maxWidth={'lg'} className={'px-0'}>
				<Grid container>
					<Grid item xs={12} md={3}>
						<Typography variant={matchUpMd ? 'h5' : 'h10'} color={'black1'}>
							{t('메뉴.찾아오시는 길')}
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						display={'flex'}
						alignItems={'center'}
						mt={matchUpMd ? 0 : '1rem'}
					>
						<Typography
							variant={matchUpMd ? 'caption2' : 'caption3'}
							color={'black1'}
						>
							{t(
								'찾아오시는 길.서울시 영등포구 영신로 166, 908호(영등포 반도아이비밸리)'
							)}
						</Typography>
					</Grid>
				</Grid>
				<Grid item sm={12} mt={matchUpMd ? '4rem' : '3rem'}>
					<iframe
						src={
							'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.381040292598!2d126.89954927732886!3d37.52251417205012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f0eef8487b5%3A0xea332bc7178094ce!2z7JiB65Ox7Y-sIOuwmOuPhOyVhOydtOu5hOuwuOumrA!5e0!3m2!1sko!2skr!4v1712017234867!5m2!1sko!2skr'
						}
						width={'100%'}
						height={matchUpMd ? '400px' : '280px'}
						className={'CompanyLocation-Map'}
						allowFullScreen=''
						loading='lazy'
						referrerPolicy='no-referrer-when-downgrade'
					/>
				</Grid>
			</Container>
		</Box>
	);
};

export default Location;
