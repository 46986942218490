import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	Box,
	ButtonBase,
	Container,
	Grid,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { newsInfo } from 'data/news-info';

// ----------------------------------------------------------------------

const initialData = {
	id: 1,
	date: '',
	title: {
		kr: '',
		en: ''
	},
	img: undefined,
	contents: {
		kr: '',
		en: ''
	}
};

// ----------------------------------------------------------------------

const NewsDetailView = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const { id } = useParams();
	const [data, setData] = useState(initialData);

	useEffect(() => {
		const tmp = newsInfo.filter((item) => item.id === parseInt(id));
		setData(tmp[0]);
	}, [id]);

	const handleNavigateNews = () => {
		navigate(-1);
	};

	// ----------------------------------------------------------------------

	return (
		<Box px={matchUpMd ? 0 : '2rem'}>
			<Container maxWidth={'md'} className={'px-0'}>
				<Grid
					container
					display={'flex'}
					flexDirection={'column'}
					alignItems={'center'}
				>
					<Grid item xs={12} mt={matchUpMd ? '12rem' : '5rem'}>
						<Typography
							variant={matchUpMd ? 'caption5' : 'caption3'}
							color={'gray6'}
						>
							{t('메뉴.새소식')} |{' '}
							{i18n.language === 'ko' ? data.date.ko : data.date[i18n.language]}
						</Typography>
					</Grid>
					<Grid item xs={12} mt={'1.5rem'}>
						<Typography variant={matchUpMd ? 'h9' : 'h13'} color={'black1'}>
							{data.title[i18n.language] === undefined
								? data.title['ko']
								: data.title[i18n.language]}
						</Typography>
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth={'lg'} className={'px-0'}>
				{data.img !== undefined && (
					<Grid item xs={12} container mt={matchUpMd ? '8rem' : '3rem'}>
						<Box
							className={'NewsDetail-Img-Wrapper'}
							pt={
								matchUpMd ? 'calc(480/ 1200 * 100%)' : 'calc(158/ 335 * 100%)'
							}
						>
							<img src={data.img} alt={data.title} />
						</Box>
					</Grid>
				)}
			</Container>
			<Container maxWidth={'md'} className={'px-0'}>
				<Grid item xs={12} container mt={'6rem'} px={matchUpMd ? 0 : '2rem'}>
					<Typography
						variant={'caption3'}
						color={'black1'}
						whiteSpace={'break-spaces'}
					>
						{data.contents[i18n.language] === undefined
							? data.contents['ko']
							: data.contents[i18n.language]}
					</Typography>
				</Grid>
			</Container>

			<Box
				className={'News-List'}
				pt={matchUpMd ? '8rem' : '5rem'}
				pb={'12rem'}
			>
				<ButtonBase className={'News-List-Button'} onClick={handleNavigateNews}>
					<Typography
						variant={matchUpMd ? 'caption3' : 'caption5'}
						color={'black2'}
					>
						{t('목록 보기')}
					</Typography>
				</ButtonBase>
			</Box>
		</Box>
	);
};

export default NewsDetailView;
