import {
	Box,
	Container,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Trans } from 'react-i18next';

import images from 'assets/images';

// ----------------------------------------------------------------------

const MainImage = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			className={matchUpMd ? 'MainImage-Small' : 'M-MainImage-Small'}
			sx={{
				background: `linear-gradient(rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6) 100%), url(${images.NewsMain})`,
				backgroundPosition: ' 50% 65%',
				backgroundSize: 'cover'
			}}
		>
			<Container className={'MainImage-Small-Title'}>
				<Typography
					variant={matchUpMd ? 'h1' : 'h11'}
					color={'white1'}
					align={'center'}
				>
					<Trans i18nKey={'뉴스룸.CONTENTS MADANG News Room'} />
				</Typography>
			</Container>
		</Box>
	);
};

export default MainImage;
