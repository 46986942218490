import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import {
	StyledEngineProvider,
	CssBaseline,
	ThemeProvider
} from '@mui/material';

import 'i18n';
import 'style/index.scss';

import theme from 'theme';
import Routes from 'routes';

import { NavigationScroll } from 'layouts/components';

// ----------------------------------------------------------------------

function App() {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		document.title = t('앱');
		document.body.className = i18n.language;
	}, [i18n.language]);

	// ----------------------------------------------------------------------

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme()}>
				<CssBaseline />
				<BrowserRouter>
					<NavigationScroll>
						<Routes />
					</NavigationScroll>
				</BrowserRouter>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

export default App;
