import { Box, useMediaQuery, useTheme } from '@mui/material';

import images from 'assets/images';

// ----------------------------------------------------------------------

const MainImage = () => {
	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	// ----------------------------------------------------------------------

	return (
		<Box
			className={matchUpMd ? 'MainImage' : 'M-MainImage'}
			sx={{
				background: `linear-gradient(rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0) 100%), url(${images.CompanyMain})`,
				backgroundPosition: 'center',
				backgroundSize: 'cover'
			}}
		></Box>
	);
};

export default MainImage;
